// --------------------------------------------------------
// Main Style
// --------------------------------------------------------
@import "layout/main_style";
@import url(https://fonts.googleapis.com/css?family=Roboto:400,300,500,700);
@import url(https://fonts.google.com/?query=robo&selection.family=Josefin+Sans:400,600,700|Roboto:300,400,500,700,900);

// --------------------------------------------------------
/* Vendor Style*/
// --------------------------------------------------------

// --------------------------------------------------------
/* Container Style*/
// --------------------------------------------------------

@font-face { font-family: josefinsans; src: url(#{$fonts-path}josefinsans.eot); }
@font-face { font-family: josefinsans; src: url(#{$fonts-path}josefinsans.otf) format("truetype"); }

.header { width:100vw; height:62px; border-bottom:1px solid rgba(255,255,255,0.8); background:rgba(0,0,0,0.2); position:fixed; top:0; left:0; z-index:999;
    @media #{$medium-down} { border:none; background:#fff; height:auto; } 
    .header_center {
        .header_page_area { 
            .page_botton { color:#eee;
                &:hover { color:#109af5; transition:0.3s; }
            }
        }
        .header_logo { 
            .logo1 { display:block; }
            .logo2 { display:none; }
        }
    }
}

.btn.orange:hover { color:#fff; border:2px solid #e52b2b; }
.btn-orange { border:2px solid #fff; color: #fff; float:right; margin:14px 0 0 10px; width:120px; p { padding:6px 0px; } }
.btn-orange:after { background-color: #e52b2b; }

.content1 { width:100%; min-height:200px; position:relative; overflow:hidden;
    @media #{$medium-down} { margin-top:50px; }
    .owl-carousel {
        &.pc { display:block;  
            @media #{$small-down} { display:none; }
        }
        &.mo { display:none; 
            @media #{$small-down} { display:block; }
        }
    }
    .cycle_box { position:relative;
        .cycle_img { width:100vw; position:relative; }
        img { position:relative; z-index:9; }
        .cycle_center { width:800px; position:absolute; left:calc(50% - 400px); text-align:center; z-index:99; color:#fff; padding:120px 0; transform:translateY(-120px);
            background:url(../images/index/circle_bg.png); background-position:center center; background-size:60%; background-repeat:no-repeat;
            -webkit-animation:con1_cir 2s 1.3s forwards linear; animation:con1_cir 2s 1.3s forwards linear;
            @media #{$small-down} { width:300px; height:300px; top:calc(35% - 150px); left:calc(50% - 150px); padding:50px 0; transform:translateY(-0px); background-size:100%; -webkit-animation:con1_cir_mo 2s 1.3s forwards linear; animation:con1_cir_mo 2s 1.3s forwards linear; }
            @media #{$small-down} { -webkit-animation:none; animation:none; } //640- 
            .cycle_title { font-weight:600; padding:40px 0 0 0; font-style:italic; opacity:0; -webkit-animation:con1_word2 1s 2.3s forwards linear; animation:con1_word2 1s 2.3s forwards linear; 
                @media #{$small-down} { opacity:1; -webkit-animation:none; animation:none; padding:80px 0 0 0; } //640-
            }
            .cycle_word { padding:20px 0 80px 0; opacity:0; -webkit-animation:con1_word1 0.5s 2.3s forwards linear; animation:con1_word1 0.5s 2.3s forwards linear; 
                @media #{$small-down} { padding:20px 0 45px 0; }
                @media #{$small-down} { opacity:1; -webkit-animation:none; animation:none; } //640-
            }
            .cycle_botton { width:160px; border:2px solid #fff; margin:0 auto; border-radius:30px; opacity:0; p { padding:8px 0px; } -webkit-animation:con1_botton 0.5s 2.6s forwards linear; animation:con1_botton 0.5s 2.6s forwards linear; 
                @media #{$small-down} { opacity:1; -webkit-animation:none; animation:none; } //640-    
            }
        }
    } 
    .scroll_icon { width:60px; height:60px; position:absolute; bottom:30px; left:calc(50% - 20px); z-index:100; cursor:pointer;  
        .svg_icon { width:40px; height:40px; margin:0 auto; 
            .dot { -webkit-animation:svg_icon 3s infinite; animation: svg_icon 3s infinite linear; }
        } 
        .scroll_word { width:100%; text-align:center; color:#fff; margin:5px 0 0 0; }
        @media #{$medium-down} { display:none; }
    }
    .arrow { width:50px; height:50px; position:absolute; top:calc(50% - 25px); z-index:99; cursor:pointer;
        @media #{$medium-down} { width:30px; height:30px; }
        &.left { left:150px; transition:0.3s; 
            @media #{$medium-down} { left:20px; }
        }
        &.right { right:150px; transition:0.3s; 
            @media #{$medium-down} { right:20px; }
        }
        &:hover {
            &.left { left:145px; transition:0.3s; 
                @media #{$medium-down} { left:20px; }
            }
            &.right { right:145px; transition:0.3s; 
                @media #{$medium-down} { right:20px; }
            }
        }
    }
}
.content1 .owl-carousel .owl-item.active .cycle_box .cycle_img img { -webkit-animation:con1_img 2s 0.6s forwards linear; animation: con1_img 2s 0.6s forwards linear; 
    @media #{$small-down} { -webkit-animation:none; animation:none; } //640-
}
.content2 { width:100%; min-height:600px; overflow:hidden; padding:60px 0; position:relative; background:url(../images/index/con2_bg.png); background-attachment:fixed; background-size:cover; background-position:center center;
    @media #{$large-down} { background-attachment:scroll; }
    @media #{$medium-down} { padding:20px 0; }
    .con2_cover { position:absolute; top:0; left:0; width:100%; height:100%; z-index:3;
        background:-webkit-linear-gradient(to bottom , rgba(68, 116, 161, 1), rgba(68, 116, 161, 1), rgba(68, 116, 161, 1), rgba(68, 116, 161, 0) );
        background:linear-gradient(to bottom , rgba(68, 116, 161, 1), rgba(68, 116, 161, 1), rgba(68, 116, 161, 0), rgba(68, 116, 161, 0) );
        background:-webkit-linear-gradient(to bottom , rgba(68, 116, 161, 1), rgba(68, 116, 161, 1), rgba(68, 116, 161, 1), rgba(68, 116, 161, 0) );
        background:-o-linear-gradient(to bottom , rgba(68, 116, 161, 1), rgba(68, 116, 161, 1), rgba(68, 116, 161, 1), rgba(68, 116, 161, 0) );
        background:-moz-linear-gradient(to bottom , rgba(68, 116, 161, 1), rgba(68, 116, 161, 1), rgba(68, 116, 161, 1), rgba(68, 116, 161, 0) );
        background:-ms-linear-gradient(to bottom , rgba(68, 116, 161, 1), rgba(68, 116, 161, 1), rgba(68, 116, 161, 1), rgba(68, 116, 161, 0) ); }
    .con2_center { width:calc(100% - 40px); max-width:980px; margin:0 auto; position:relative; z-index:10;
        @media #{$xxlarge-only} {} //1441~1920
        .con2_article { width:100%; text-align:center; line-height:150%; color:#fff; letter-spacing:0.5px; }
        .content_title {
            @media #{$xsmall-only} { padding:10px 0; } //0~480
        }
    }
    .con2_top_cover { position:absolute; width:50%; height:500px; left:0; top:-140px;
        background:linear-gradient( 160deg , rgba(41, 83, 123,1), rgba(41, 83, 123,0), rgba(41, 83, 123,0) );
        background:-webkit-linear-gradient( 160deg , rgba(41, 83, 123,1), rgba(41, 83, 123,0), rgba(41, 83, 123,0) );
        background:-o-linear-gradient( 160deg , rgba(41, 83, 123,1), rgba(41, 83, 123,0), rgba(41, 83, 123,0) );
        background:-moz-linear-gradient( 160deg , rgba(41, 83, 123,1), rgba(41, 83, 123,0), rgba(41, 83, 123,0) );
        background:-ms-linear-gradient( 160deg , rgba(41, 83, 123,1), rgba(41, 83, 123,0), rgba(41, 83, 123,0) ); }
} 
.ie { .content2 { 
    background-attachment:scroll;
} }
.bottom_tri { width:calc(100% + 100px); height:80px; transform:rotate(-1.8deg); position:absolute; left:-50px; bottom:-35px; 
    @media #{$large-down} { bottom:-28px; transform:rotate(-2.5deg); } //1280-
    &.con2 { background:#e5f5f8; }
    &.con3 { background:#fff; }
}
.content3 { background:#e5f5f8; padding:0 0 150px 0; position:relative;
    @media #{$large-down} { padding:0 0 60px 0; } //1280-
    .con3_center { width:calc(100% - 300px); float:right; overflow:hidden; margin:-50px 0 0 0; position:relative;
        @media #{"screen and (min-width:1441px) and (max-width:1680px)" } { width:calc(100% - 160px); }
        @media #{$xlarge-only} { width:calc(100% - 150px); } //1281~1440
        @media #{$large-only} { width:calc(100% - 30px); margin:-80px auto 0 auto; } //1025~1280
        @media #{$medium-down} { width:calc(100% - 0px); margin:-80px auto 0 auto; } //1024- 
        .left_title { width:300px; float:left; background:#273859; padding:45px 0;
            @media #{$xlarge-only} { width:300px; } //1281~1440
            @media #{$medium-down} { width:calc(100% - 40px); float:none; margin:0 auto; padding:1px 0; } //1024-
            .con3_title { width:calc(100% - 40px); margin:30px auto; color:#fff; text-align:center; 
                @media #{$xsmall-only} { margin:25px auto 10px auto; } //0~480
            }
            .con3_word { width:calc(100% - 100px); margin:20px auto 50px auto; color:#fff; line-height:156%; 
                @media #{$medium-down} { width:calc(100% - 40px); margin:0px auto 25px auto; }
            }
            .cycle_arrow { width:calc(100% - 100px); margin:30px auto; overflow:hidden;
                .right_arrow { width:30px; height:30px; float:right; cursor:pointer; }
                .arrow_line { width:2px; height:30px; float:right; margin:0 35px; background:#fff; }
                .left_arrow { width:30px; height:30px; float:right; cursor:pointer; }
            }
        }
        .right_cyclearea { width:calc(100% - 50px); position:absolute; bottom:0; right:-250px; height:390px;
            @media #{$medium-down} { width:calc(100% + 250px); float:none; margin:25px auto 0 auto; height:auto; position:relative; right:10px; }
            .cycle_box { 
                overflow:hidden; 
                background:#fff; 
                cursor:pointer;

                .cycle_img { 
                    width:100%; 
                    background-size:cover; 
                    background-position:center center; 

                    &.no_pic { img { opacity:1; } }

                    img { width:100%; opacity:0; } 
                }

                .word_title, 
                .word_article,
                .more_box {
                    width: calc(100% - 60px); 
                    overflow: hidden; 
                    margin:20px auto; 
                }

                .word_title { 
                    height: 25px;
                    line-height: 25px;
                    margin-bottom: 15px;
                    color:#333; 
                    font-weight:600; 
                    letter-spacing:0.5px; 
                    transition:0.4s;
                    display:-webkit-box; 
                    text-overflow:ellipsis; 
                    -webkit-line-clamp:1; 
                    -moz-line-clamp:1; 
                    -ms-line-clamp:1; 
                    -webkit-box-orient:vertical;
                }
                .word_article { 
                    height: 45px;
                    line-height: 1.5;
                    margin:0 auto; 
                    color:#333; 
                    display:-webkit-box; 
                    text-overflow:ellipsis; 
                    -webkit-line-clamp:2; 
                    -moz-line-clamp:2; 
                    -ms-line-clamp:2; 
                    -webkit-box-orient:vertical; 

                    @media screen and (max-width:700px) {
                        height: 40px;
                        line-height: 1.5;
                    }
                }
                .more_box { 
                    .more_arrow { float:right; width:15px; height:15px; margin:5px 0 0 10px; }
                    .more_word { float:right; letter-spacing:1px; color:#273859; font-weight:600; transition:0.4s; }
                } 
                .arrow_line{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;fill-rule:evenodd;opacity:0.7;}
                .arrow_line_news{fill:none;stroke:#273859;stroke-linecap:round;stroke-linejoin:round;stroke-width:4px;fill-rule:evenodd;opacity:1;transition:0.4s;}
                &:hover {
                    .word_title { color:#d83d41; transition:0.4s; }
                    .more_box {
                        .more_word { color:#d83d41; transition:0.4s; }
                    }
                    .arrow_line_news{ stroke:#d83d41; transition:0.4s; } 
                }
            }
        }
    }
    .owl-dots { display:none; }
}
.ie { .content3 { 
    .con3_center {
        .right_cyclearea { height:415px; 
            .cycle_box { 
                .word_article { height:41px; }
                .more_box { .more_word { margin:2px 0 0 0; } } 
            }
        }
    }
} }
.content4 { width:100%; background:#fff; overflow:hidden; padding:0px 0 60px 0; position:relative;
    .con4_center { width:calc(100% - 580px); margin:0 auto; overflow:hidden;
        @media #{"screen and (min-width:1441px) and (max-width:1680px)" } { width:calc(100% - 320px); }
        @media #{$xlarge-only} { width:calc(100% - 280px); } //1281~1440
        @media #{$large-down} { width:calc(100% - 20px); } //1280-
        .content_title {
            @media #{$xsmall-only} { padding:15px 0; } //0~480
        }
        .information_box { width:calc(100% / 2 - 20px); height:280px; margin:0 10px 20px 10px; float:left; overflow:hidden; cursor:pointer;
            @media #{$large-down} { width:calc(100% / 2 - 20px); height:240px; margin:0 10px 20px 10px; } //1280-
            @media #{$medium-down} {
                height: auto;
            }
            @media #{$small-down} { width:calc(100% - 0px); height:auto; margin:0 0px 20px 0px; }
            &.blue { background:#34aeff; }
            &.yellow { background:#f0940a; }
            &.dustyblue { background:#6191a8; }
            &.orange { background:#f45a2b; }
            .left_img { width:330px; float:left; background-size:cover; height:100%;
                @media #{$xlarge-only} { width:285px; } //1281~1440
                @media #{$large-only} { width:270px; } //1025~1280
                @media #{$medium-down} { width:120px; background-size: 80%; margin:30px 0 0 0;
                    background-position: center;
                    background-repeat: no-repeat; } //1024-
                img { height:100%; margin:0 0 -5px 0; opacity:0;
                    @media #{$large-only} {} //1025~1280
                    @media #{$medium-down} { height:auto; } //1024-
                } 
            }
            .right_word { width:calc(100% - 330px - 46px); height:280px; padding:0 23px; float:right; display:table; color:#fff; position:relative;
                @media #{$xlarge-only} { width:calc(100% - 285px - 46px); } //1281~1440
                @media #{$large-only} { width:calc(100% - 270px - 46px); height:240px; } //1025~1280
                @media #{$medium-down} { width:calc(100% - 150px - 0px); height:160px; padding:0 23px 0 0; } //1024-
                .time { position:absolute; top:23px; right:23px; text-align:center; padding:8px 10px; border:1px solid #fff; letter-spacing:1px; transition:0.4s;
                    @media #{$large-only} { top:15px; right:15px; padding:5px 7px; } //1025~1280
                    @media #{$medium-down} { top:15px; right:15px; padding:5px 7px; } //1024-
                    p { font-family:josefinsans; line-height:150%; } span { padding:0 0 2.5px 0; border-bottom:1px solid #fff; } 
                }
                .word_center { width:100%; display:table-cell; vertical-align:middle; 
                    .sort_box { width:80px; background:rgba(255,255,255,0.3); border-radius:15px; padding:5px 10px; text-align:center; 
                        @media #{$xlarge-only} { width:70px; } //1281~1440
                        @media #{$large-only} { width:70px; } //1025~1280
                        @media #{$medium-down} { width:70px; } //1024-
                    }
                    .title { margin:20px 0; overflow:hidden; display:-webkit-box; text-overflow:ellipsis; -webkit-line-clamp:1; -moz-line-clamp:1; -ms-line-clamp:1; -webkit-box-orient:vertical; 
                        @media #{$large-only} {} //1025~1280
                        @media #{$medium-down} { margin:10px 0; } //1024-
                    }
                    .article { line-height:150%; overflow:hidden; display:-webkit-box; text-overflow:ellipsis; -webkit-line-clamp:3; -moz-line-clamp:3; -ms-line-clamp:3; -webkit-box-orient:vertical; 
                        @media #{$large-only} {} //1025~1280
                        @media #{$medium-down} { -webkit-line-clamp:1; -moz-line-clamp:1; -ms-line-clamp:1; } //1024-

                    }
                } 
                .more_box { position:absolute; bottom:23px; right:23px; overflow:hidden; 
                    @media #{$large-only} {} //1025~1280
                    @media #{$large-down} { display:none; } //1024-
                    .more_arrow { float:right; width:15px; height:15px; margin:5px 0 0 10px; }
                    .more_word { float:right; letter-spacing:1px; color:#fff; font-weight:600; transition:0.4s; }
                } 
            }
            &:hover {
                .right_word {
                    .time { transition:0.8s; background:#fff; -webkit-transform: rotateY(360deg); -moz-transform: rotateY(360deg); transform: rotateY(360deg); -webkit-transform-style: preserve-3d; transform-style: preserve-3d; }
                }
                &.blue { .right_word { .time { color:#34aeff; span { border-bottom:1px solid #34aeff; } } } }
                &.yellow { .right_word { .time { color:#f0940a; span { border-bottom:1px solid #f0940a; } } } }
                &.dustyblue { .right_word { .time { color:#6191a8; span { border-bottom:1px solid #6191a8; } } } }
                &.orange { .right_word { .time { color:#f45a2b; } span { border-bottom:1px solid #f45a2b; } } }
            }
        }
    }
}
.ie { .content4 { 
    .con4_center {
        .information_box { .right_word { 
            .word_center { 
                .title { height:25px; }
                .article { height:74px; } 
            } 
            .more_box { .more_word { margin:2px 0 0 0; } }
        } } 
    }
} }
.content5 { background:#e5f5f8; padding:30px 0 60px 0; position:relative;
    .con5_center { width:calc(100% - 600px); margin:0 auto; overflow:hidden;
        @media #{"screen and (min-width:1441px) and (max-width:1680px)" } { width:calc(100% - 320px); }
        @media #{$xlarge-only} { width:calc(100% - 300px); } //1281~1440
        @media #{$large-down} { width:calc(100% - 40px); } //1280-
        .content_title {
            @media #{$xsmall-only} { padding:15px 0; } //0~480
        }
        .left_imgarea { width:600px; float:left; position:relative; background:url(#{$images-path}index/img6.jpg) center top;
            @media #{$large-down} { width:100%; } //1280-
            .img_box { 
                width:100%; 
                background-size:cover; 
                background-position:center center; 

                &.no_pic {
                    img {
                        opacity: 1;
                    }
                }

                img { 
                    width:100%; 
                    opacity: 0;
                } 
            }
            .img_word { width:100%; padding:0px 0; position:absolute; bottom:0; left:0; position:absolute; overflow: hidden; box-shadow: 0 10px 20px rgba(165, 207, 229, 0.5);  z-index: 100; box-sizing: border-box;
                &::before { content: ""; position:absolute; top: 0px; left: 0px; right: 0px; bottom: 0px; z-index: -1; -webkit-filter: blur(20px); filter: blur(20px); margin: -30px;  background: url(#{$images-path}index/img6.jpg) center bottom; background-size: cover; }
                .img_word_center{ position:relative; width:100%; height:100%; 
                    .img_word_color { width:100%; height:100%; position:absolute; left:0; top:0; background:#a5cfe5; opacity:0.5; }
                    .article_center { width:calc( 100% - 120px); overflow:hidden; margin:0 auto; position:relative;
                        @media #{$large-down} { width:calc(100% - 40px); } //1280-
                        .left_time { width:60px; height:60px; float:left; background:#9db5c2; color:#fff; text-align:center; padding:6px 8px; margin:10px 0; letter-spacing:1px; 
                            @media #{$large-down} { width:40px; height:40px; padding:10px 10px; } //1280-
                            p { margin:9px 0; font-family:josefinsans; line-height:150%; 
                                @media #{$large-down} { margin:2px 0; } //1280-
                            } 
                            span { padding:0 0 2.5px 0; border-bottom:1px solid #fff; } 
                        }
                        .center_article { width:calc(100% - 90px); margin:20px 0; float:right;
                            .news_title { color:#333; letter-spacing:0.5px; overflow:hidden; display:-webkit-box; text-overflow:ellipsis; -webkit-line-clamp:1; -moz-line-clamp:1; -ms-line-clamp:1; -webkit-box-orient:vertical; }
                            .news_article { color:#333; margin:8px 0 0 0; overflow:hidden; display:-webkit-box; text-overflow:ellipsis; -webkit-line-clamp:1; -moz-line-clamp:1; -ms-line-clamp:1; -webkit-box-orient:vertical;  }
                        }
                    }
                }
            }
        }
        .right_news_area { width:calc(100% - 620px); height:calc(100% - 13px); float:right; border-top:1px solid #b5b5b5;
            @media #{$large-down} { width:100%; } //1280-
            .con5_news_box { width:100%; height:calc(100% / 4 - 1px); padding:1.5px 0; border-bottom:1px solid #b5b5b5; overflow:hidden; display:block; cursor:pointer;
                .left_time { width:60px; height:60px; float:left; background:#9db5c2; color:#fff; text-align:center; padding:8px 10px; margin:10px 0; letter-spacing:1px; 
                    @media #{$large-down} { width:40px; height:40px; padding:10px 10px; } //1280-
                    p { margin:9px 0; font-family:josefinsans; line-height:150%; 
                        @media #{$large-down} { margin:2px 0; } //1280-
                    } 
                    span { padding:0 0 2.5px 0; border-bottom:1px solid #fff; } 
                }
                .center_article { width:calc(100% - 88px - 60px - 20px); margin:10px; float:left;
                    @media #{$large-down} { width:calc(100% - 88px - 33px - 0px); } //1280-
                    .news_title { color:#333; letter-spacing:0.5px; overflow:hidden; display:-webkit-box; text-overflow:ellipsis; -webkit-line-clamp:1; -moz-line-clamp:1; -ms-line-clamp:1; -webkit-box-orient:vertical; }
                    .news_article { color:#959595; margin:10px 0 0 0; overflow:hidden; display:-webkit-box; text-overflow:ellipsis; -webkit-line-clamp:2; -moz-line-clamp:2; -ms-line-clamp:2; -webkit-box-orient:vertical;  }
                }
                .right_arrow { width:calc(59px - 40px); padding:20px; height:20px; margin:20px 0; float:left; border-left:1px solid #b5b5b5; 
                    @media #{$large-down} { margin:12px 0; padding:20px 0px 20px 20px; } //1280-
                    .arrow_line_news{ fill:none; stroke:#273859; stroke-linecap:round; stroke-linejoin:round; stroke-width:2px;fill-rule:evenodd;opacity:1;transition:0.4s;}
                }
                &:hover {
                    .left_time { transition:0.8s; -webkit-transform: rotateY(360deg); -moz-transform: rotateY(360deg); transform: rotateY(360deg); -webkit-transform-style: preserve-3d; transform-style: preserve-3d; }
                    .right_arrow {
                        .arrow_line_news { transform:translateX(5px); transition:0.4s; }
                    }
                }
            }
        }

        .loading {
            position:relative;
            width: 100%;
            height:50px;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 50%;
                width: 50px;
                height: 50px;
                margin-left: -25px;
                background-image: url(#{$images-path}loading.gif);
                background-repeat: no-repeat;
                background-size: 100%;
            }
        }
    }
}
.ie { .content5 { .con5_center { 
    .left_imgarea { .img_word { .img_word_center { .article_center { .center_article { .news_article { height:20px; } } } } } }
    .right_news_area { .con5_news_box { .center_article { 
        .news_title { height:24px; }
        .news_article { height:40px; } 
    } } } 
} } }
.content6 { width:100%; overflow:hidden; padding:90px 0 160px 0; position:relative; background:url(../images/index/con6_bg.png); background-attachment:scroll; background-size:cover; background-position:center 60%;
    @media #{$large-down} { padding:40px 0 160px 0; background-attachment:scroll; }
    .center_circle { width:360px; height:360px; position:absolute; left:calc(50% - 180px); top:calc(50% - 180px); img { width:100%; height:100%; } 
        @media #{$small-down} { width:250px; height:250px; left:calc(50% - 125px); top:calc(32% - 125px); }
    }
    .con6_center { width:calc(100% - 40px); max-width:980px; margin:0 auto; position:relative; z-index:10;
        @media #{$xxlarge-only} {} //1441~1920
        .content_title { font-style:italic; color:#273859; 
            @media #{$xsmall-only} { padding:15px 0; } //0~480
        }
        .con6_article { width:100%; text-align:center; line-height:150%; color:#535353; letter-spacing:0.5px; }
        .cycle_botton { width:160px; margin:30px auto 0 auto; border:1px solid #e12b2b; color:#e12b2b; border-radius:30px; text-align:center; p { padding:8px 0px; } }
    }
}

.more_botton { position:absolute; overflow:hidden; right:300px; top:0; cursor:pointer; transition:0.4s;
    @media #{"screen and (min-width:1441px) and (max-width:1680px)" } { right:170px; }
    @media #{$xlarge-only} { right:150px; } //1281~1440
    @media #{$large-down} { right:12px; transform:scale(0.8); top:-30px; } //1280-
    .arrow { float:right; width:12px; height:12px; transition:0.4s;  
        .arrow_line_news{ fill:none;stroke:#273859;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px;fill-rule:evenodd;opacity:1;transition:0.4s; }
    } 
    .word { float:right; margin:-2.6px 10px 0 0; color:#273859; }
    &.con4 { top:50px; 
        @media #{$large-down} { top:41px; } //1280-
    }
    &.con5 { top:80px; 
        @media #{$large-down} { top:71px; } //1280-
    }
    &:hover { transform:translateX(-5px); transition:0.4s; .arrow { transform:translateX(2.5px); transition:0.4s; } }
}

.canvas_area { position:relative; z-index:98; margin:50px 0 100px 0;
    &.pc { display:block;  
        @media #{$medium-down} { display:none; }
    }
    &.mo { display:none; 
        @media #{$medium-down} { display:block; 
            img { width:1024px; margin:0 auto; max-width:1024px; } 
        }
    }
    .canvas { opacity:0.3; margin:50px 0 0 0; }
    .country_area { position:absolute; top:0; left:0; width:100%; height:100%; z-index:98;
        .country_center { position:relative; width:100%; height:100%;
            .country_dot { position:absolute; width:80px; 
                .dot_box { width:30px; height:30px; position:relative; margin:0 auto;
                    .dot_center { width:15px; height:15px; margin:7.5px; background:#fff; border-radius:40px; }
                    .dot_bg { width:30px; height:30px; border-radius:40px; position:absolute; top:-7.5px; left:0px; background:rgba(255,255,255,0.3); }
                }
                .country_name { color:#fff; letter-spacing:1px; text-align:center; text-shadow:0 0 10px #273859; }
                //裝飾點點
                &.taiwan {    right: calc(10% - 40px); top: 75%; .dot_box { .dot_bg { -webkit-animation:dot 5s 0.2s infinite linear; animation: dot 5s 0.2s infinite linear; } } }
                &.japan {     left:  calc(43% - 40px); top: 50%; .dot_box { .dot_bg { -webkit-animation:dot 5s 0.4s infinite linear; animation: dot 5s 0.4s infinite linear; } } } //日本
                &.thanland {  right: calc(22% - 40px); top: 20%; .dot_box { .dot_bg { -webkit-animation:dot 5s 0.6s infinite linear; animation: dot 5s 0.6s infinite linear; } } } //泰國
                &.india {     left:  calc(26% - 40px); top: 40%; .dot_box { .dot_bg { -webkit-animation:dot 5s 0.8s infinite linear; animation: dot 5s 0.8s infinite linear; } } } //印度
                &.ph {        right: calc(36% - 40px); top: 20%; .dot_box { .dot_bg { -webkit-animation:dot 5s 1s infinite linear; animation: dot 5s 1s infinite linear; } } } //菲律賓
                &.au {        left:  calc(10% - 40px); top: 20%; .dot_box { .dot_bg { -webkit-animation:dot 5s 1.2s infinite linear; animation: dot 5s 1.2s infinite linear; } } } //澳洲
                &.indonesia { left:  calc(33% - 40px); top: 45%; .dot_box { .dot_bg { -webkit-animation:dot 5s 1.4s infinite linear; animation: dot 5s 1.4s infinite linear; } } } //印尼
                &.macau {     right: calc(43% - 40px); top: 60%; .dot_box { .dot_bg { -webkit-animation:dot 5s 1.6s infinite linear; animation: dot 5s 1.6s infinite linear; } } } //澳門
                &.hong {      left:  calc(16% - 40px); top: 75%; .dot_box { .dot_bg { -webkit-animation:dot 5s 0.4s infinite linear; animation: dot 5s 0.4s infinite linear; } } } //香港
                &.mala {      left:  calc(50% - 40px); top: 0; .dot_box { .dot_bg { -webkit-animation:dot 5s 0.8s infinite linear; animation: dot 5s 0.8s infinite linear;  } } } //馬來西亞
                &.singapore { right: calc(29% - 40px); top: 75%; .dot_box { .dot_bg { -webkit-animation:dot 5s infinite linear; animation: dot 5s infinite linear;  } } } //新加坡
                //裝飾點點
                &.small { transform:scale(0.8); opacity:0.7; }
                &.left1 { left:1%; top:60%; }
                &.left2 { left:19%; top:12%; } 
                &.left3 { left:37%; top:65%; }
                &.right1 { right:1%; top:20%; }
                &.right2 { right:12%; top:45%; }
                &.right3 { right:29%; top:52%; }
                &.right4 { right:43%; top:25%; }
            }
        }
    }
}

.arrow_line{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;fill-rule:evenodd;opacity:0.7;}


@keyframes svg_icon {
    0% { transform: translate( 0px , 0); opacity:1; }
    50% { transform: translate( 0px , 10px); opacity:0.5; }
    100% { transform: translate( 0px , 0); opacity:1; }
}
@-webkit-keyframes svg_icon {
    0% { -webkit-transform: translate( 0px , 0); opacity:1; }
    50% { -webkit-transform: translate( 0px , 10px); opacity:0.5; }
    100% { -webkit-transform: translate( 0px , 0); opacity:1; }
}
@keyframes con1_img {
    0% { transform:scale(1); }
    100% { transform:scale(1.08); }
}
@-webkit-keyframes con1_img {
    0% { -webkit-transform:scale(1); }
    100% { -webkit-transform:scale(1.08); }
}
@keyframes con1_cir {
    0% {  background-size:60%; }
    100% { background-size:50%; }
}
@-webkit-keyframes con1_cir {
    0% {  background-size:60%; }
    100% { background-size:50%; }
}
@keyframes con1_cir_mo {
    0% {  background-size:110%; }
    100% { background-size:100%; }
}
@-webkit-keyframes con1_cir_mo {
    0% {  background-size:110%; }
    100% { background-size:100%; }
}
@keyframes con1_word1 {
    0% { transform:translateX(-15px); opacity:0; }
    100% { transform:translateX(0px); opacity:1; }
}
@-webkit-keyframes con1_word1 {
    0% { -webkit-transform:translateX(-15px); opacity:0; }
    100% { -webkit-transform:translateX(0px); opacity:1; }
}
@keyframes con1_word2 {
    0% { transform:translateX(15px); opacity:0; }
    100% { transform:translateX(0px); opacity:1; }
}
@-webkit-keyframes con1_word2 {
    0% { -webkit-transform:translateX(15px); opacity:0; }
    100% { -webkit-transform:translateX(0px); opacity:1; }
}
@keyframes con1_botton {
    0% { transform:scale(0.9); opacity:0; }
    100% { transform:scale(1); opacity:1; }
}
@-webkit-keyframes con1_botton {
    0% { -webkit-transform:scale(0.9); opacity:0; }
    100% { -webkit-transform:scale(1); opacity:1; }
}
@keyframes dot {
    0% { transform:scale(1); opacity:1; }
    50% { transform:scale(0.8); opacity:0.5; }
    100% { transform:scale(1); opacity:1; }
}
@-webkit-keyframes dot {
    0% { -webkit-transform:scale(1); opacity:1; }
    50% { -webkit-transform:scale(0.8); opacity:0.5; }
    100% { -webkit-transform:scale(1); opacity:1; }
}

//---------------------------------------------------------------- Media Query

// @media #{$xsmall-only} {} //0~480

// @media #{$small-up} {} //481+
// @media #{$small-only} {} //480~640
// @media #{$small-down} {} //640-

// @media #{$medium-up} {} //641+
// @media #{$medium-only} {} //641~1024
// @media #{$medium-down} {} //1024-

// @media #{$large-up} {} //1281+
// @media #{$large-only} {} //1025~1280
// @media #{$large-down} {} //1280-

// @media #{$xlarge-up} {} //1281+
// @media #{$xlarge-only} {} //1281~1440
// @media #{$xlarge-down} {} //1440-

// @media #{$xxlarge-up} {} //1281+
// @media #{$xxlarge-only} {} //1441~1920
// @media #{$xxlarge-down} {} //1920-


@-webkit-keyframes bgin {
    from { -webkit-transform: skew(-45deg) translate(-150%); transform: skew(-45deg) translate(-150%); }
    to { -webkit-transform: skew(-45deg) translate(-50%); transform: skew(-45deg) translate(-50%); }
}
@keyframes bgin {
    from { -webkit-transform: skew(-45deg) translate(-150%); transform: skew(-45deg) translate(-150%); }
    to { -webkit-transform: skew(-45deg) translate(-50%); transform: skew(-45deg) translate(-50%); }
}
@-webkit-keyframes bgout {
    from { -webkit-transform: skew(-45deg) translate(-50%); transform: skew(-45deg) translate(-50%); }
    to { -webkit-transform: skew(-45deg) translate(50%); transform: skew(-45deg) translate(50%); }
}
@keyframes bgout {
    from { -webkit-transform: skew(-45deg) translate(-50%); transform: skew(-45deg) translate(-50%); }
    to { -webkit-transform: skew(-45deg) translate(50%); transform: skew(-45deg) translate(50%); }
}

.clearfix {
    &::after {
        content: ".";
        display: block;
        clear: both;
        visibility: hidden;
        line-height: 0;
        height: 0
    }
}